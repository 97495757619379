<template>
  <div>
    <el-button type="primary" @click="dialogFormVisible = true">新增人员</el-button>


    <el-dialog title="新增人员" :visible.sync="dialogFormVisible" @close="closeAndClean" >
      <el-form :model="form" label-position="left" >
        <el-form-item label="用户编码" :label-width="formLabelWidth" >
          <el-input v-model="form.userCode" autocomplete="off" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="form.username" autocomplete="off" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth">

          <el-select v-model="form.role"  placeholder="请选择">
            <el-option value="admin"></el-option>
            <el-option value="manager"></el-option>
            <el-option value="qc"></el-option>
            <el-option value="worker"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAndClean">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>



</template>


<style scoped>




.contain{
  width: 100%;
  height: 800px;

}
.contain .col{
  width: 100%;
  height:50px;
  background: coral;
  display: flex;
}

.contain .col .cell{
  height: 50px;
  flex: 1;
  background: aqua;
}


</style>

<script>

import axios from "axios";

export default {
  data() {
    return {


      dialogFormVisible: false,
      form: {
        userCode:'',
        username:'',
        password:'',
        name:'',
        role:'',

      },
      formLabelWidth: '120px'
    };
  },
  methods:{
    closeAndClean(){
      this.dialogFormVisible = false;
      this.form ={
        userCode:'',
        username:'',
        password:'',
        name:'',
        role:'',

      };
    },
    submitForm() {

      if(this.form.userCode.trim() === '' || this.form.username.trim() === '' || this.form.password.trim() === '' ||
          this.form.name.trim() === '' || this.form.role.trim() === ''){
        this.$message.error("请正确填写数据")
        return;
      }




      axios.post(process.env.VUE_APP_BASE_API+'/api/user/add', this.form)
          .then(response => {
            console.log('成功:', response.data);
            this.dialogFormVisible = false;
            this.form ={
              id:'',
              username:'',
              password:'',
              name:'',
              role:'',

            };

            this.$emit('formSubmitted');

          })
          .catch(error => {
            console.error('请求失败:', error);
          });
    }
  }
};

</script>