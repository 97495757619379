<template>
  <div>

    <div class="common">
      <div class="common-header">
        <div class="serch">
          <div class="select-serch">
            <div style="margin-top: 15px;">
              <el-input placeholder="请输入内容" v-model="input" class="input-with-select" clearable @clear="fetchDataByOne">
                <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px">
                  <el-option label="订单编号" value="1"></el-option>
                  <el-option label="订单状态" value="2"></el-option>
                  <el-option label="地区" value="3"></el-option>
                  <el-option label="接单日期" value="4"></el-option>
                  <el-option label="出货日期" value="5"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search"  @click="fetchData"></el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="tow-button">
          <div class="button">
            <OrderAddDialog @addOrder="getAddOrderEvent"></OrderAddDialog>
          </div>
          <div class="button">
            <el-button type="primary" @click="downloadOrderTemplateRequest"
                       :loading="isDownload"
                       :disabled="isDownload">下载模板</el-button>

          </div>
          <div class="button">
            <el-button type="primary" @click="toInventoryPage">库存</el-button>
          </div>


        </div>

      </div>
      <div class="common-middle">
        <div class="bar"></div>
        <div class="table">
          <el-table
              :data="tableData"
              style="width: 100%"
              border>
            <el-table-column
                width="70px"
                prop="n"
                label="序号">
              <template slot-scope="scope">
                {{ ( scope.$index + 1).toString().padStart(2, '0') }}
              </template>
            </el-table-column>
            <el-table-column
                prop="poId"
                label="订单编号"
            >
            </el-table-column>
            <el-table-column
                width="90px"
                prop="quantity"
                label="数量"
            >
            </el-table-column>
            <el-table-column
                width="90px"
                prop="shipment"
                label="出货量"
            >
            </el-table-column>
            <el-table-column
                width="90px"
                prop="inventory"
                label="库存"
            >
            </el-table-column>
            <el-table-column
                width="90px"
                prop="lack"
                label="缺数"
            >

            </el-table-column>
            <el-table-column
                width="90px"
                prop="scrap"
                label="报废数量"
            >

            </el-table-column>
            <el-table-column
                width="90px"
                prop="realInput"
                label="实际投入"
            >
            </el-table-column>

            <el-table-column
                prop="orderDate"
                label="接单日期"
                width="110px"
            >
            </el-table-column>

            <el-table-column
                width="110px"
                prop="shipmentDate"
                label="出货日期"
            >
            </el-table-column>

            <el-table-column
                prop="region"
                label="地区"
                width="90px"
            >
            </el-table-column>

            <el-table-column
                prop="orderStatus"
                label="订单状态"
                width="90px">
              <template v-slot="scope">
                <span v-if="scope.row.orderStatus === 1" style="color: green;">未开始</span>
                <span v-else-if="scope.row.orderStatus === 2" style="color: red;">进行中</span>
                <span v-else-if="scope.row.orderStatus === 3" style="color: blue;">已完成</span>
                <span v-else-if="scope.row.orderStatus === 4" style="color: gray;">已关闭</span>
                <span v-else>未知状态</span>
              </template>
            </el-table-column>



            <el-table-column
              label="操作"
              width="120px">
              <div class="op" slot-scope="scope">
                <div class="sub-op" v-if="scope.row.orderStatus != 1">
                  <OrderDetailDialog :row="scope.row" @updateCountSuccess="fetchData"></OrderDetailDialog>
                </div>
                <div class="sub-op" v-if="scope.row.orderStatus === 1">
                  <EditOrder :row="scope.row" @editOrder="getEditOrderEvent"></EditOrder>
                </div>
                <div class="sub-op" v-if="scope.row.orderStatus === 1">
                  <template>
                  <el-popconfirm
                      title="确认"
                      @confirm="confirmOrder(scope.row.poId)"
                  >
                    <el-button  type="text" size="small" slot="reference">开始</el-button>
                  </el-popconfirm>
                  </template>
                </div>
                <div class="sub-op" v-if="scope.row.orderStatus === 1">
                  <template>
                    <el-popconfirm
                        title="确认"
                        @confirm="cancelOrder(scope.row.poId)"
                    >
                      <el-button  type="text" size="small" slot="reference">删除</el-button>
                    </el-popconfirm>
                  </template>
                </div>
                <div class="sub-op" v-if="scope.row.orderStatus === 2">
                  <template>
                    <el-popconfirm
                        title="确认"
                        @confirm="finish(scope.row.poId)"
                    >
                      <el-button  type="text" size="small" slot="reference">取消</el-button>
                    </el-popconfirm>
                  </template>
                </div>
                <div class="sub-op" v-if="scope.row.orderStatus === 3">
                  <template>
                    <el-popconfirm
                        title="确认"
                        @confirm="shipment(scope.row.poId)"
                    >
                      <el-button  type="text" size="small" slot="reference">出库</el-button>
                    </el-popconfirm>
                  </template>
                </div>
              </div>

          </el-table-column>

            <el-table-column

                label="物料清单"
                width="100px"
            >
              <div class="op" slot-scope="scope">
                <el-button @click="downloadRequest(scope.row.poId)" type="text" size="small">下载</el-button>

              </div>
            </el-table-column>
          </el-table>
        </div>

      </div>
      <div class="common-foot">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            class="custom-pagination"
            style="margin-left: auto;margin-top: 15px;margin-right: 15px;">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import OrderAddDialog from "@/components/OrderAddDialog.vue";
import OrderDetailDialog from "@/components/OrderDetailDialog.vue";
import EditOrder from "@/components/EditOrder.vue";
import axios from "axios";

export default {

  created() {
    this.fetchData();
  },
  components: {
    OrderAddDialog,
    OrderDetailDialog,
    EditOrder,
  },
  data() {
    return {
      tableData: [],
      input:'',
      select:'1',
      total:0,
      pageSize:10,
      currentPage:1,

      isUploading:false,
      isDownload:false,

    }
  },
  methods:{


    downloadOrderTemplateRequest(){
      this.isDownload = true;

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/download/orderTemplate',{responseType: 'blob'})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `orderTemplate.xlsx`); // 设置下载文件名
            document.body.appendChild(link);
            link.click();

          })
          .catch(error => {
            console.error('Error:', error);
          }).finally(() => {
        this.isDownload = false;
      });
    },




    //跳转到实时库存页面
    toInventoryPage(){
      this.$router.push(`/dashboard/inventory`);
    },



    //订单出库
    shipment(id){
      var form = {
        poId : id
      };


      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/order/shipment',{params:form})
          .then(response => {
            if(response.data.success){
              this.$message({
                type:"success",
                message:id+"已出库",
              })
              this.fetchData();
            }else {
              this.$message.error("订单出库失败")
            }


          })
          .catch(error => {
            console.error('Error:', error);
          });
    },



    //结束订单
    finish(id){
      var form = {
        poId : id
      };


      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/order/cancelGoingOrder',{params:form})
          .then(response => {
            if(response.data.success){
              this.$message({
                type:"success",
                message:id+"已取消",
              })
              this.fetchData();
            }else {
              this.$message.error("订单取消失败")
            }


          })
          .catch(error => {
            console.error('Error:', error);
          });
    },


    cancelOrder(id){

      var form = {
        poId : id
      };


      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/order/cancelOrder',{params:form})
          .then(response => {
            if(response.data.success){
              this.$message({
                type:"success",
                message:id+"已取消",
              })
              this.fetchData();
            }else {
              this.$message.error("订单取消失败!!!")
            }


          })
          .catch(error => {
            console.error('Error:', error);
          });


    },


    confirmOrder(id){

      axios.put(process.env.VUE_APP_BASE_API+'/api/order/confirm/'+id)
          .then(response => {
              if(response.data.success){
                  this.$message({
                    type:"success",
                    message:id+"已开始",
                  })
                this.fetchData();
              }else {
                this.$message.error("订单确认失败!!!")
              }
          })
          .catch(error => {
              this.$message.error(error);
          });

    },
    //发送下载请求
    downloadRequest(id){

      var form = {
        poId : id
      };

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/download/excel',{params:form,responseType: 'blob'})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}.xlsx`); // 设置下载文件名
            document.body.appendChild(link);
            link.click();

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },

    handleSizeChange(size){
      this.pageSize = size;
      this.fetchDataByOne();
    },
    handleCurrentChange(currentPage){
      this.currentPage = currentPage;
      this.fetchData();
    },


    handleClick(row) {
      this.$router.push({ name: 'orderdetail', params: { id: row.no } });
    },
    fetchData() {
      var form = {};

      if(this.select == '1'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          poId:this.input
        }
      }
      if(this.select == '2'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          orderStatus:this.input
        }
      }
      if(this.select == '3'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          region:this.input
        }
      }
      if(this.select == '4'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          orderDate:this.input
        }
      }
      if(this.select == '5'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          shipmentDate:this.input
        }
      }


      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/order/find',{params:form})
          .then(response => {
            this.tableData = response.data.data.orderList;
            this.total = response.data.total;
            if(this.currentPage > 1 && this.tableData.length === 0){
              this.fetchDataByOne();
            }

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    fetchDataByOne() {
      var form = {};
      this.currentPage = 1;

      if(this.select == '1'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          poId:this.input
        }
      }
      if(this.select == '2'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          orderStatus:this.input
        }
      }
      if(this.select == '3'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          region:this.input
        }
      }
      if(this.select == '4'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          orderDate:this.input
        }
      }
      if(this.select == '5'){
        form = {
          currentPage:this.currentPage,
          pageSize:this.pageSize,
          shipmentDate:this.input
        }
      }


      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/order/find',{params:form})
          .then(response => {
            this.tableData = response.data.data.orderList;
            this.total = response.data.total;
          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    getAddOrderEvent(success){

      if(success){
        this.$message({
          type:"success",
          message:'添加成功'
        });
        this.fetchData()
      }else {
        this.$message.error('添加失败');

      }
    },
    getEditOrderEvent(success){

      if(success){
        this.$message({
          type:"success",
          message:'编辑成功'
        });
        this.fetchData()
      }else {
        this.$message.error('编辑失败');

      }
    },

  },
}
</script>

<style scoped>
/* 样式可以根据需要添加 */

.custom-pagination >>> .el-pagination__total {
  color: black;
}

.custom-pagination >>> .el-pagination__jump {
  color: black;
}


.common{
  width: 100%;
  height: 100%;

}

.common .common-header{
  width: 100%;
  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end;
  flex-direction: column;
}
.common .common-header .serch{
  width: 100%;
  height: 80px;
  background: #4B6EA8;
  display: flex;
  justify-content: center; /* 水平居中 */

}
.common .common-header .serch .select-serch{
  width: 500px;
  height: 70px;
}


.common .common-header .tow-button{
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end; /* 垂直靠下排列 */
}

.common .common-header .tow-button .button{
  width: 100px;
  height: 30px;

  margin-bottom: 15px;
  margin-left: 15px;
}

.common .common-middle{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}
.common .common-middle .table{
  width: 90%;
  min-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.common .common-foot{
  background: #4B6EA8;
  width: 100%;
  display: flex;
}

.bar{
  width: 100%;
  height: 50px;
  background: #4B6EA8;
}

.op{
  display: flex;
}
.sub-op{
  margin-left: 5px;
}

.custom-pagination .el-pagination__total {
  color: black;
}

</style>
