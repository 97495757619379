import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from '@/components/LoginPage';
import DashBoard from '@/components/DashBoard';


import OrderPage from "@/components/OrderPage.vue";



import GoodsPage from "@/components/GoodsPage.vue";
import DataCenter from "@/components/DataCenter.vue";
import UserPage from "@/components/UserPage.vue";

import OrderItemPage from "@/components/OrderItemPage.vue";
import ReportPage from "@/components/ReportPage.vue";
import InventoryPage from "@/components/InventoryPage.vue";

Vue.use(Router);

export default new Router({
    mode: 'history',  // 使用 HTML5 history 模式
    routes: [
        {
            path: '/',
            name: 'Login',
            component: LoginPage
        },
        {
            path: '/dashboard',
            component: DashBoard,
            children: [
                {
                    path: '',
                    name: 'DashboardHome',
                    component: DataCenter
                },
                {
                    path: 'dataCenter',
                    name: 'dataCenter',
                    component: DataCenter
                },
                {
                    path: 'goods',
                    name: 'goods',
                    component: GoodsPage
                },
                {
                    path: 'order',
                    name: 'order',
                    component: OrderPage
                },

                {
                    path: 'user',
                    name: 'user',
                    component: UserPage
                },
                {
                    path: 'report/:id',
                    name: 'report',
                    component: ReportPage
                },
                {
                    path: 'orderItem/:id/:kind',
                    name: 'orderItem',
                    component: OrderItemPage
                },
                {
                    path: 'inventory',
                    name: 'inventory',
                    component: InventoryPage
                },
            ]
        }
    ]
});
