<template>
  <div>

    <div class="common">
      <div class="common-header">
        <div class="serch">

        </div>


      </div>
      <div class="common-middle">

        <div class="table">

          <el-card class="box-card">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <span>返回{{total}}条</span>
              <span style="margin-left: auto;">

              </span>
            </div>



          </el-card>

          <el-divider></el-divider>

          <template >
            <div v-for="(inventory,index) in tableData" :key="inventory.key">
              <el-card class="box-card">
                <div>
                  <span>{{ String(index+1).padStart(3, '0') }}</span>
                  <span style="margin-left: 40px">{{inventory.key}}</span>
                  <span style="margin-left: 40px">{{inventory.value}}</span>


                </div>

              </el-card>
              <el-divider></el-divider>
            </div>
          </template>







        </div>

      </div>
      <div class="common-foot">
        <div class="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {



  mounted() {

    this.fetchInventory();



  },
  methods: {
    //查找实时库存列表
    //订单出库
    fetchInventory(){

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/order/inventory')
          .then(response => {
            if(response.data.success){
              this.tableData = response.data.data.inventoryList;
              this.total = response.data.total;


            }else {
              this.$message.error("获取失败")
            }


          })
          .catch(error => {
            console.error('Error:', error);
          });
    },

  },
  components: {},
  data() {
    return {
      tableData: [],

      total:0,

    }
  },

}
</script>

<style scoped>
/* 样式可以根据需要添加 */

.custom-table .el-table {
  font-size: 12px; /* 调整表格字体大小 */
}

.common{
  width: 100%;
  height: 100%;

}

.common .common-header{
  width: 100%;


  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end;
  flex-direction: column;
}
.common .common-header .serch{
  background: #4B6EA8;
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center; /* 水平居中 */

}
.common .common-header .serch .select-serch{
  width: 500px;
  height: 70px;

}


.common .common-header .tow-button{
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end; /* 垂直靠下排列 */
}

.common .common-header .tow-button .button{
  width: 100px;
  height: 30px;

  margin-bottom: 15px;
  margin-left: 15px;
}

.common .common-middle{
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

}

.bar{
  width: 100%;
  height: 50px;
  background: #4B6EA8;
}


.common .common-middle .table{
  width: 100%;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

}
.common .common-foot{
  background: #4B6EA8;
  width: 100%;
  display: flex;
}

</style>
